import { cn } from '@eq/utils/classnames'
import Button, { ButtonProps } from 'antd-button-color'
import { omit } from 'lodash'

import Link from 'next/link'
import { forwardRef } from 'react'
import { LinkType } from '..'

type ExtendedButtonProps = Omit<LinkType, 'activePaths'> & ButtonProps
interface NavLinkProps extends ExtendedButtonProps {
  active?: boolean
  activeProps?: ButtonProps
  className?: string
}

const LinkComponent = forwardRef(({ className = '', active, title, activeProps = {}, ...props }: Partial<NavLinkProps>, ref) => {
  return (
    <Button
      ref={ref}
      size="large"
      onClick={props.onClick}
      className={cn('nav-link-button', className, {
        'text-inherit hover:text-info': props.type === 'link',
      })}
      {...props}
      {...(active ? activeProps : {})}
    >
      {title}
    </Button>
  )
})

export default function NavLink(linkProps: NavLinkProps) {
  const props = omit(linkProps, ['activePaths'])
  if (props.href) {
    if (props.href.includes('https://') || props.href.includes('http://')) {
      return <LinkComponent href={props.href} {...props} />
    }
    return (
      <Link href={props.href} key={props.href}>
        <LinkComponent {...props} />
      </Link>
    )
  }
  return <LinkComponent {...props} />
}
