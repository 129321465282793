import { LinkProps } from 'antd/lib/typography/Link'
import { forwardRef, ReactNode } from 'react'
import { cn } from '@eq/utils/classnames'
import cls from './TextLink.module.css'

interface TextLinkProps extends LinkProps {
  underline?: boolean
  highlight?: boolean
  children: ReactNode
}

const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ className = '', underline = true, highlight = true, children, ...props }, ref) => {
    return (
      <a
        ref={ref}
        className={cn(cls.textLink, className, {
          [cls.hideUnderline]: !underline,
          [cls.highlight]: highlight,
        })}
        {...props}
      >
        {children}
      </a>
    )
  }
)

export default TextLink
