import { Button, ButtonProps } from 'antd'
import { useIntl } from 'react-intl'
import { useLoginModal } from '../LoginView/LoginViewModal'

interface LoginButtonProps extends ButtonProps {}

// export const openLoginWindow = (params?: Record<string, string>) => {
//   if (typeof window !== 'undefined') {
//     return window.open(
//       window.origin + `/login?${queryString.stringify({ frame: true, ...params })}`,
//       'EezyQuote Login',
//       'menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=450,height=600'
//     )
//   }
// }

export default function LoginButton({ onClick, ...props }: LoginButtonProps) {
  const intl = useIntl()
  const loginModal = useLoginModal()

  return (
    <>
      <Button
        onClick={async (e) => {
          loginModal()
          onClick?.(e)
        }}
        {...{ type: 'primary', ...props }}
      >
        {props.children || <span>{intl.formatMessage({ defaultMessage: 'Login' })}</span>}
      </Button>
    </>
  )
}
