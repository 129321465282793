import { SettingOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Space } from 'antd'
import Avatar from '@eq/components/Avatar'
import TextLink from '@eq/components/TextLink'
import { UserTeam } from '@eq/interfaces/users'
import { useTeams, useUser } from '@eq/hooks/useUser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { BsChevronExpand, BsGear, BsPlus } from 'react-icons/bs'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './UserBreadcrumbs.module.css'
import { useAccount } from '@eq/hooks/useAccount'

interface ProfileProps {
  name?: string
  email?: string
  slug: string
  image?: string
  icon?: ReactNode
  small?: boolean
  href?: string
}
const Profile = (props: ProfileProps) => {
  return (
    <Space className="group justify-between flex w-full" size="large">
      <Space className="flex items-center">
        <Avatar alt={props?.email || props.slug || ''} src={props.image} size={props.small ? 20 : 28} />
        {props.href ? (
          <Link href={props.href}>
            <a href={props.href} className="mb-0 hover:text-blue-500 pointer-events-auto">
              {props.name}
            </a>
          </Link>
        ) : (
          props.name
        )}
      </Space>
      {props.icon && <div className="text-right">{props.icon}</div>}
    </Space>
  )
}

const SettingsIcon = (props: { href: string }) => {
  return (
    <Link href={props.href}>
      <TextLink href={props.href} underline={false} className="text-inherit leading-none hover:text-info opacity-50 hover:opacity-100">
        <BsGear size={18} />
      </TextLink>
    </Link>
  )
}

interface BreadcrumbsMenuProps {
  data?: UserTeam[]
  slug?: string
  name?: string
  image?: string
}

const BreadcrumbsMenu = (props: BreadcrumbsMenuProps) => {
  const intl = useIntl()
  const { asPath } = useRouter()

  return (
    <Menu mode="inline" className={`shadow-lg rounded-md ${styles.BreadcrumbsMenu}`} selectedKeys={[asPath]}>
      <Menu.ItemGroup className="p-0" title={intl.formatMessage({ defaultMessage: 'Personal Account' }).toUpperCase()}>
        <Menu.Item key="1" icon={<UserOutlined />}>
          <Link href={`/${props.slug}`}>My Overview </Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<UnorderedListOutlined />}>
          <Link href={'/dashboard/requests'}>My Requests </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<SettingOutlined />}>
          <Link href="/account">My Settings </Link>
        </Menu.Item>
      </Menu.ItemGroup>
      {props.data?.[0] && (
        <>
          <Menu.Divider />
          <Menu.ItemGroup title={intl.formatMessage({ defaultMessage: 'Teams' }).toUpperCase()}>
            {props.data?.map((item) => (
              <Menu.Item key={`/${item.slug || item._id}`}>
                <Profile
                  href={`/${item.slug || item._id}`}
                  name={item.name || item.slug || item._id}
                  slug={item.slug}
                  image={item.image}
                  small
                  icon={<SettingsIcon href={`/teams/${item.slug || item._id}/settings`} />}
                />
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        </>
      )}

      <Menu.Divider />
      <Menu.Item key={'/teams/create'}>
        <Link href="/teams/create">
          <a href="/teams/create" className="group">
            <div className="flex justify-between items-center pl-1">
              <FormattedMessage defaultMessage="Create a Team" />
              <BsPlus size={24} className="group-hover:text-info mr-1 opacity-50 group-hover:opacity-100" />
            </div>
          </a>
        </Link>
      </Menu.Item>
    </Menu>
  )
}

export default function UserBreadcrumbs() {
  const [user] = useUser()
  const account = useAccount()
  const teams = useTeams()

  if (!user) return null
  if (!user && !account) return null

  const currentAccount = account || user

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={
          <BreadcrumbsMenu
            slug={user.email || user.slug || user.id}
            name={user.name || user.email || ''}
            image={user.image || ''}
            data={teams}
          />
        }
      >
        <div className="profile-breadcrumbs-container cursor-pointer space-x-1 flex items-center">
          <Profile
            name={currentAccount?.name || currentAccount?.email || currentAccount?.slug}
            email={currentAccount?.email || ''}
            slug={currentAccount.slug}
            image={currentAccount.image || ''}
          />
          <Button
            size="small"
            className="expand-button px-1 text-center flex place-items-center"
            icon={<BsChevronExpand className="group-hover:text-blue-500" size={16} />}
          />
        </div>
      </Dropdown>
      <style jsx>{`
        :global(.profile-breadcrumbs-container:not(:hover) .expand-button:not(:hover)) {
          border-color: transparent;
          box-shadow: none;
        }
      `}</style>
    </>
  )
}
