import { CheckOutlined } from '@ant-design/icons'
import { HTMLAttributes, KeyboardEvent, MouseEvent } from 'react'
import { cn } from '@eq/utils/classnames'

interface ClickableProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
  onClick: (e: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => void
}

export default function Clickable({ onClick, onKeyDown, ...props }: ClickableProps) {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') onClick?.(event) // allows event to trigger with keyboard
        onKeyDown?.(event)
      }}
      {...props}
    />
  )
}

export const ClickableToggle = ({ className, active = false, children, ...other }: ClickableProps & { active?: boolean }) => {
  return (
    <Clickable
      className={cn(
        'rounded-md shadow-md flex space-x-4 cursor-pointer px-4 py-2 items-center focus:ring-info relative overflow-hidden border-none outline-none ring-inset',
        className,
        {
          'ring-info ring-2': active,
          'ring-accent-2 ring-1': !active,
        }
      )}
      {...other}
    >
      {children}
      {active && (
        <div className="absolute check-marker top-0 -left-3 w-5 h-5">
          <CheckOutlined className="text-white z-10 relative" />
          <style jsx>{`
            .check-marker::before {
              content: '';
              z-index: 0;
              @apply w-10 h-20 bg-info absolute rotate-45 transform -top-7 -left-7;
            }
          `}</style>
        </div>
      )}
    </Clickable>
  )
}
