import Container from '@eq/components/Container'
import Header from '@eq/components/Layout/HeaderLayout'
import { cn } from '@eq/utils/classnames'
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import { Affix } from 'antd'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useState } from 'react'

interface LayoutProps {
  children: ReactNode
  className?: string
  header?: ReactNode
  menu?: ReactNode
  footer?: ReactNode
  title?: string
}

const height = 30
const width = 12
export default function Layout(props: LayoutProps) {
  const [affixed, setAffixed] = useState(false)
  return (
    <>
      <Head>
        <title>{props.title ? `${props.title} - EezyQuote` : 'EezyQuote'}</title>
      </Head>
      <SkipNavLink title="skip navigation" />
      {props.header || <Header border={!props.menu} />}
      <div id="layout-content" className={cn(props.className || '')} fixed-padding={'10px'}>
        {props.menu && (
          <Affix onChange={(affixed) => setAffixed(affixed || false)}>
            <div
              className="border-b-1 bg-white border-accent-2 max-w-full relative"
              style={{
                boxShadow: affixed ? '0 0 15px 0 rgba(0,0,0,.1)' : undefined,
              }}
            >
              <Container>
                <div className="flex items-center transition-all">
                  <div
                    className="text-center"
                    style={{
                      width,
                      height,
                    }}
                  >
                    <div
                      className={cn('animate__animated ', {
                        animate__fadeInDown: affixed,
                        'animate__fadeOutUp pointer-events-none': !affixed,
                      })}
                      style={{ animationDuration: '200ms' }}
                    >
                      <Link href="/">
                        <a href="/">
                          <Image
                            key="header-user-logo-small"
                            src="/images/header-user-logo.svg"
                            alt="EezyQuote"
                            width={width}
                            height={height}
                            layout="intrinsic"
                          />
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className="relative transition-all ease-in-out" style={{ transform: `translateX(${affixed ? 15 : -width}px)` }}>
                    {props.menu}
                  </div>
                </div>
              </Container>
            </div>
          </Affix>
        )}
        <SkipNavContent />
        {props.children}
      </div>
      {props.footer && <footer>{props.footer}</footer>}
      <style jsx global>
        {`
          .mobile-nav-open #layout-content {
            padding-top: var(--header-height);
          }
        `}
      </style>
    </>
  )
}
