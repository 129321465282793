import { cn } from '@eq/utils/classnames'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { Button } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import { LinkType } from '..'

const LinkComponent = (props: LinkType & { active?: boolean; button?: boolean }) => {
  if (props.button) {
    return (
      <Button type="primary" block size="large" onClick={props.onClick}>
        {props.title}
      </Button>
    )
  }
  return (
    <span
      className={cn('block pl-3 pr-4 py-2 border-l-4 text-base font-medium  -mb-1', {
        'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700': !props.active,
        'bg-blue-50 border-blue-500 text-blue-700': props.active,
      })}
      onClick={props.onClick}
      onKeyDown={(e) => e.key === '13' && props?.onClick?.(e)}
      role="button"
      tabIndex={-1}
    >
      {props.title}
    </span>
  )
}

const MobileNavigation = (props: { links: LinkType[] }) => {
  const { asPath } = useRouter()
  return (
    <div className="pt-2 pb-3 space-y-2 divide-y divide-light-blue-400">
      {props?.links?.map((link, i) => {
        const active = [...(link?.activePaths || []).filter(Boolean), link?.href].filter(Boolean).includes(asPath)
        return (
          <div key={i} className={cn('py-2', link.className)}>
            {link.href ? (
              <Link href={link.href}>
                <a>
                  <LinkComponent {...link} active={active} />
                </a>
              </Link>
            ) : (
              <LinkComponent {...link} active={active} />
            )}
          </div>
        )
      })}
    </div>
  )
}

const MobileDrawer = (props: {
  isOpen: boolean
  close: () => void
  linkGroups: Array<LinkType[]>
  topSection: ReactNode
  bottomSection?: ReactNode
  open: () => void
}) => {
  const setHeaderFixed = (flag) => {
    if (typeof window !== 'undefined') {
      const body = document.body
      if (body) {
        if (flag) body.classList.add('mobile-nav-open')
        else body.classList.remove('mobile-nav-open')
      }
    }
  }

  useEffect(() => {
    setHeaderFixed(props.isOpen)
  }, [props.isOpen])

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={props.isOpen}
        onClose={() => {
          props.close()
        }}
        onOpen={() => {
          props.open()
        }}
        // variant="persistent"
      >
        <div className="flex justify-between space-y-8 flex-col p-6" style={{ height: 'var(--drawer-height)' }}>
          <div className="space-y-4 justify-between">
            {props.topSection}
            {props?.linkGroups?.map((group, i) => (
              <MobileNavigation key={i} links={group} />
            ))}
          </div>
          {props.bottomSection && <div className="bottom-section">{props.bottomSection}</div>}
        </div>
      </SwipeableDrawer>
      <style jsx global>{`
        .MuiBackdrop-root {
          @apply pointer-events-none hidden !important;
        }
        .MuiDrawer-modal {
          @apply pointer-events-none;
        }
        .MuiDrawer-modal .MuiDrawer-paperAnchorBottom {
          @apply shadow-none border-t;
          pointer-events: all;
        }
        :root {
          --drawer-height: calc(var(--vh) - calc(var(--header-height) - 1px));
        }
      `}</style>
    </>
  )
}

export default MobileDrawer
