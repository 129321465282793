import { cn } from '@eq/utils/classnames'
import { gravatar } from '@eq/utils/gravatar'
import { forwardRef, useMemo } from 'react'
interface AvatarProps {
  alt: string
  slug?: string
  size?: number
  src?: string | null
  rounded?: boolean
  layout?: 'fill'
}

const generateAvatarFromAlt = (value = '') => `data:image/svg+xml;base64,` + Buffer.from(gravatar(value)).toString('base64')

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({ alt = '', size = 50, rounded = true, layout, slug = '', ...props }, ref) => {
  const src = useMemo(() => props.src || generateAvatarFromAlt(slug || alt), [props.src, slug, alt])

  return (
    <div
      ref={ref}
      className={cn('overflow-hidden leading-normal ring-1 ring-accent-2', {
        'rounded-full': rounded,
      })}
    >
      <div className={cn('gravatar relative flex items-center justify-center')}>
        <img
          src={src}
          alt={alt}
          className="object-fill"
          {...(layout === 'fill'
            ? {
                layout: 'fill',
              }
            : {
                layout: 'responsive',
                width: size,
                height: size,
              })}
        />
      </div>
      <style jsx>{`
        .gravatar {
          width: ${size};
          height: ${size};
        }
      `}</style>
    </div>
  )
})

export default Avatar
