import FancyRequestButton from '@eq/components/FancyRequestButton'
import LoginButton from '@eq/components/LoginButton'
import { Button } from 'antd'
import { betaConfig } from 'beta-config'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CgMenuRightAlt } from 'react-icons/cg'
import { useIntl } from 'react-intl'
import { useDisclosure } from 'react-use-disclosure'
import { LinkType } from '..'
import MobileDrawer from '../MobileDrawer'
import NavLink from '../NavLink'

export default function PublicHeader() {
  const { isOpen, close, open, toggle } = useDisclosure(false)
  const intl = useIntl()

  const centerLinks = [
    ...(betaConfig.development
      ? [
          {
            href: '/suppliers',
            title: intl.formatMessage({ defaultMessage: 'Suppliers' }),
            activePaths: [],
            className: 'beta-hidden',
          },
          {
            href: '/faq',
            title: intl.formatMessage({ defaultMessage: 'Faq' }),
            activePaths: [],
            className: 'beta-hidden',
          },
        ]
      : []),
  ]

  const rightLinks = [
    {
      href: '/signup',
      title: intl.formatMessage({ defaultMessage: 'Sign Up' }),
      type: 'primary',
    },
  ]

  return (
    <nav className="public-header-component relative transition-all">
      <div className="mx-auto lg:divide-y lg:divide-gray-200">
        <div className="relative h-16 flex justify-between">
          <LeftContainer />
          <CenterContainer links={centerLinks} />
          <RightContainer links={rightLinks} toggleMenu={toggle} />
        </div>
      </div>
      <MobileContainer isOpen={isOpen} close={close} open={open} linkGroups={[centerLinks]} />
    </nav>
  )
}

const LeftContainer = () => {
  return (
    <div className="relative z-10 px-2 flex lg:px-0">
      <div className="flex-shrink-0 flex items-center">
        <Link href="/">
          <a>
            <Image key="header-public-logo" src="/images/header-logo.svg" alt="EezyQuote" width={80} height={60} />
          </a>
        </Link>
      </div>
    </div>
  )
}

const CenterContainer = (props: { links: LinkType[] }) => {
  const { asPath } = useRouter()
  return (
    <div className="relative flex-1 px-2 flex items-center justify-center no-print">
      <div className="w-full sm:max-w-xs">
        <div className=" sm:ml-6 sm:flex sm:space-x-8 items-center justify-center">
          <FancyRequestButton />
        </div>
        <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center justify-center">
          {props.links.map((link) => {
            const active = [...(link?.activePaths || []), link.href].includes(asPath)
            return <NavLink data-test-id={`public-header-center-${link.title}`} key={link.href} active={active} {...link} />
          })}
        </div>
      </div>
    </div>
  )
}

const RightContainer = (props: { toggleMenu: () => void; links: LinkType[] }) => {
  const intl = useIntl()
  const { asPath } = useRouter()
  return (
    <>
      <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-8 no-print">
        <LoginButton type="text" className="hover:text-info hover:bg-transparent" />
        {props.links.map((link) => {
          const active = [link.href].includes(asPath)
          return <NavLink key={link.href} active={active} {...link} />
        })}
      </div>
      <div className="-mr-2 flex items-center sm:hidden no-print">
        {/* <!-- Mobile menu button --> */}
        <Button
          type="primary"
          ghost
          className="inline-flex items-center justify-center p-2 text-2xl border-none shadow-none hover:text-info"
          aria-expanded="false"
          size="large"
          onClick={props.toggleMenu}
        >
          <span>
            <span className="sr-only">{intl.formatMessage({ defaultMessage: 'Open main menu' })}</span>
            <CgMenuRightAlt className="anticon" />
          </span>
        </Button>
      </div>
    </>
  )
}

const MobileContainer = (props: { isOpen: boolean; close: () => void; open: () => void; linkGroups: Array<LinkType[]> }) => {
  const intl = useIntl()

  return (
    <MobileDrawer
      {...props}
      topSection={
        <>
          <Link href="/contact">
            <Button type="default" block size="large">
              {intl.formatMessage({ defaultMessage: 'Contact' })}
            </Button>
          </Link>
          <LoginButton type="default" block size="large" onClick={props.close} />
          <Link href="/signup">
            <Button type="primary" block size="large">
              {intl.formatMessage({ defaultMessage: 'Sign up' })}
            </Button>
          </Link>
        </>
      }
    />
  )
}
