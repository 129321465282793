import Container from '@eq/components/Container'
import { useSession } from '@eq/hooks/useSession'
import { cn } from '@eq/utils/classnames'
import { useEffect } from 'react'
import PublicHeader from './PublicHeader'
import UserHeader from './UserHeader'

export default function Header({ border = true }) {
  const [session] = useSession()
  const header = session?.user ? <UserHeader /> : <PublicHeader />

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const header = document.getElementsByClassName('header-component')?.[0]
      const root = document.documentElement
      const headerHeight = header.getBoundingClientRect().height || 73
      root.style.setProperty(`--header-height`, headerHeight + 'px')
    }
  }, [])

  return (
    <>
      <header id="app-header" className={cn('header-component py-1 w-full bg-white z-10', { 'border-b-1 border-accent-2': border })}>
        <Container>{header}</Container>
      </header>
      <style jsx global>{`
        .mobile-nav-open #app-header {
          @apply fixed;
        }
      `}</style>
    </>
  )
}
