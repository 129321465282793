/** These are help-full for development */
const enableAll = false
const disableAll = true

const developmentEnabled = disableAll ? false : enableAll || process.env.NODE_ENV === 'development'

/** Temporary closed beta configuration, allows to show/hide different components.*/
export const betaConfig = {
  development: developmentEnabled,
  hidden: !enableAll || disableAll,
}

// TRIGGER DEPLOY
