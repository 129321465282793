import { HTMLAttributes } from 'react'
import { cn } from '@eq/utils/classnames'

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  block?: boolean
  accent?: boolean
}

export default function Container({ className, children, block, accent, ...props }: ContainerProps) {
  const InnerContainer = (
    <div
      className={cn('container', !accent && className, {
        'w-full': block,
      })}
      {...props}
    >
      <div className="container-content">{children}</div>
    </div>
  )

  if (accent) {
    return (
      <div
        className={cn('bg-accent-1 border-b-1 border-accent-2 border-solid', className, {
          'border-t': !className?.includes('border-t'),
        })}
      >
        {InnerContainer}
      </div>
    )
  }

  return InnerContainer
}
