import Fieldset from '@eq/components/Fieldset'
import { Button, notification, Popover, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Clickable from '@eq/components/Clickable'
import Image from 'next/image'
import { ReactNode, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { cn } from '@eq/utils/classnames'

interface FeedbackProps {
  children: ReactNode
}

const emotions = [
  {
    src: '/images/feedback-stars.svg',
    alt: '🤩',
  },
  {
    src: '/images/feedback-happy.svg',
    alt: '😃',
  },
  {
    src: '/images/feedback-meh.svg',
    alt: '😕',
  },
  {
    src: '/images/feedback-sad.svg',
    alt: '😭',
  },
]
export default function Feedback(props: FeedbackProps) {
  const [currentEmotion, setCurrentEmotion] = useState<number | undefined>()
  const [text, setText] = useState('')
  const intl = useIntl()
  const [visible, setVisible] = useState(false)

  const reset = () => {
    setCurrentEmotion(undefined)
    setText('')
  }

  const sendFeedback = async () => {
    notification.open({ message: intl.formatMessage({ defaultMessage: 'Thanks for your feedback.' }) })
    reset()
    setVisible(false)
  }
  const simpleFeedback = true

  if (simpleFeedback) {
    return (
      <Clickable
        tabIndex={0}
        role="button"
        aria-label={intl.formatMessage({ defaultMessage: 'Open feedback' })}
        onClick={(e) => {
          e.preventDefault()
          window?.Intercom?.('show')
        }}
      >
        {props.children}
      </Clickable>
    )
  }

  return (
    <>
      <Popover
        visible={visible}
        destroyTooltipOnHide
        placement="topLeft"
        overlayClassName="p-0 feedback-popover"
        trigger="click"
        onVisibleChange={setVisible}
        align={{
          points: ['tl', 'tl'],
          offset: [-4, -4],
        }}
        content={
          <Fieldset className="w-md max-w-full">
            <Fieldset.Content className="p-4 pt-2">
              <h4>
                <FormattedMessage defaultMessage="Feedback" />
              </h4>
              <TextArea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="resize-y"
                style={{ width: 313, maxWidth: 'calc(100vw - 2em)' }}
                placeholder={intl.formatMessage({ defaultMessage: 'Your feedback...' })}
                rows={6}
              />
            </Fieldset.Content>
            <Fieldset.Footer>
              <Fieldset.Footer.Status>
                <Space className="mr-4">
                  {emotions.map((emotion, i) => (
                    <button
                      key={i}
                      className={cn(
                        'bg-transparent rounded-full w-8 h-8 border border-solid border-1 hover:border-warning-light cursor-pointer focus-visible:ring outline-none p-0 leading-10',
                        currentEmotion !== i ? 'border-accent-2' : 'border-yellow-200 bg-yellow-300'
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        setCurrentEmotion(i)
                      }}
                    >
                      <Image src={emotion.src} alt={emotion.alt} width="20" height="20" />
                    </button>
                  ))}
                </Space>
              </Fieldset.Footer.Status>
              <Fieldset.Footer.Actions>
                <Button type="primary" onClick={sendFeedback}>
                  <FormattedMessage defaultMessage="Send" />
                </Button>
              </Fieldset.Footer.Actions>
            </Fieldset.Footer>
          </Fieldset>
        }
      >
        <div tabIndex={0} role="button" aria-label={intl.formatMessage({ defaultMessage: 'Open feedback' })}>
          {props.children}
        </div>
      </Popover>
      <style jsx global>{`
        .feedback-popover .ant-popover-arrow {
          display: none;
        }
      `}</style>
    </>
  )
}
