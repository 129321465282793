import Avatar from '@eq/components/Avatar'
import FancyRequestButton from '@eq/components/FancyRequestButton'
import Feedback from '@eq/components/Feedback'
import UserBreadcrumbs from '@eq/components/UserBreadcrumbs'
import { mutateSession, useSession } from '@eq/hooks/useSession'
import { cn } from '@eq/utils/classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { Badge, Button, Dropdown, Menu, MenuItemProps } from 'antd'
import { betaConfig } from 'beta-config'
import { signOut } from 'next-auth/client'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { CgMenuRightAlt } from 'react-icons/cg'
import { VscBell } from 'react-icons/vsc'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDisclosure } from 'react-use-disclosure'
import { LinkType } from '..'
import MobileDrawer from '../MobileDrawer'
import NavLink from '../NavLink'

const NotificationBell = () => {
  if (!betaConfig.development) return null
  return (
    <Button
      shape="circle"
      className="ml-auto flex-shrink-0 text-gray-400 border-transparent hover:text-gray-500 -mr-3 beta-hidden"
      size="large"
    >
      <Badge count={5} offset={[-3, 3]} size="small">
        <span>
          <span className="sr-only">View notifications</span>
          <VscBell className="h-6 w-6" />
        </span>
      </Badge>
    </Button>
  )
}

export default function UserHeader() {
  const { isOpen, close, open, toggle } = useDisclosure(false)
  const { asPath } = useRouter()
  const intl = useIntl()

  useEffect(() => {
    close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath])

  const centerLinks = [
    ...(betaConfig.development
      ? [
          {
            href: '/suppliers',
            title: intl.formatMessage({ defaultMessage: 'Suppliers' }),
            activePaths: [],
            className: 'beta-hidden',
          },

          {
            href: '/faq',
            title: intl.formatMessage({ defaultMessage: 'Faq' }),
            activePaths: [],
            className: 'beta-hidden',
          },
        ]
      : []),
  ]

  return (
    <nav className="user-header-component relative transition-all">
      <div className="mx-auto lg:divide-y lg:divide-gray-200">
        <div className="relative h-16 flex justify-between">
          <LeftContainer />
          <CenterContainer links={centerLinks} />
          <RightContainer toggleMenu={toggle} />
        </div>
      </div>
      <MobileContainer
        isOpen={isOpen}
        close={close}
        open={open}
        linkGroups={[
          [
            ...centerLinks,

            {
              title: intl.formatMessage({ defaultMessage: 'Logout' }),
              onClick: () => {
                signOut().then(() => mutateSession())
              },
            },
          ],
        ]}
      />
    </nav>
  )
}

const LeftContainer = () => {
  return (
    <div className="relative z-10 px-2 flex lg:px-0">
      <div className="flex-shrink-0 flex items-center">
        <Link href="/">
          <a href="/">
            <Image key="header-user-logo" src="/images/header-user-logo.svg" alt="EezyQuote" width={19} height={39} />
          </a>
        </Link>
        <svg
          width="32"
          height="32"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          color="currentColor"
          shapeRendering="geometricPrecision"
          viewBox="0 0 24 24"
          className="text-accent-2"
        >
          <path d="M16.88 3.549L7.12 20.451"></path>
        </svg>
        <div className="print-only">EezyQuote</div>
        <div className="no-print">
          <UserBreadcrumbs />
        </div>
      </div>
    </div>
  )
}

const CenterContainer = (props: { links: LinkType[] }) => {
  const { asPath } = useRouter()
  return (
    <div className="relative flex-1 px-2 flex items-center justify-center no-print">
      <div className="w-full sm:max-w-xs">
        <div className="sm:ml-6 sm:flex sm:space-x-8 items-center justify-center">
          <FancyRequestButton />
        </div>
        <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center justify-center">
          {props.links.map((link) => {
            const active = [...(link?.activePaths || []), link.href].includes(asPath)
            return <NavLink data-test-id={`public-header-center-${link.title}`} key={link.href} active={active} {...link} />
          })}
        </div>
      </div>
    </div>
  )
}

const ProfileDropdown = () => {
  const [session] = useSession()
  const intl = useIntl()
  const { pathname, asPath, push } = useRouter()
  const [loggingOut, setLoggingOut] = useState(false)

  if (!session?.user) return null

  const handleLogout = async () => {
    setLoggingOut(true)

    if (['/requests', '/request'].some((path) => pathname.includes(path))) {
      await signOut({ redirect: false, callbackUrl: asPath })
      await mutateSession()
      await push(asPath)
    } else {
      await signOut({ redirect: false }).then(async () => {
        setLoggingOut(false)
        await mutateSession()
      })
    }
  }

  const MenuItem = ({ className = '', ...props }: MenuItemProps) => (
    <Menu.Item className={cn('flex justify-between align-middle hover:bg-accent-1 cursor-pointer px-4 py-2', className)} {...props} />
  )

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu className="mt-2" style={{ minWidth: 200 }}>
          <div className="px-4 py-2 flex justify-between items-center ">
            <div className="mr-2">
              <p className="text-sm mb-0 leading-tight">{intl.formatMessage({ defaultMessage: 'Signed in as' })}</p>
              <p className="text-sm font-medium text-gray-900 truncate mb-0 leading-tight">{session.user?.email}</p>
            </div>
            <NotificationBell />
          </div>

          <Menu.Divider />
          <MenuItem key={`/${session.user?.name}`}>
            <Link href={`/${session.user?.email}`}>
              <a className="text-inherit">
                <FormattedMessage defaultMessage="Dashboard" />
              </a>
            </Link>
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="/teams/create">
            <div className="w-full group">
              <Link href="/teams/create">
                <a className="flex justify-between items-center w-full text-inherit">
                  <FormattedMessage defaultMessage="New Team" />
                  <BsPlus size={18} className="ml-6 opacity-50 group-hover:opacity-100 group-hover:text-info" />
                </a>
              </Link>
            </div>
          </MenuItem>
          <MenuItem key="/account">
            <Link href="/account">
              <a className="text-inherit">
                <FormattedMessage defaultMessage="Settings" />
              </a>
            </Link>
          </MenuItem>
          <Menu.Divider />
          <MenuItem key="logout" onClick={handleLogout} disabled={loggingOut}>
            <div data-test-id="top-dropdown-logout-btn" className="flex justify-between items-center w-full text-inherit">
              <FormattedMessage defaultMessage="Logout" />
              {loggingOut && (
                <div>
                  <LoadingOutlined spin />
                </div>
              )}
            </div>
          </MenuItem>
        </Menu>
      }
    >
      <div data-test-id="top-left-avatar" className="cursor-pointer">
        <Avatar alt={session.user?.email || session.user?.slug || ''} src={session.user?.image} size={28} />
      </div>
    </Dropdown>
  )
}

const RightContainer = (props: { toggleMenu: () => void }) => {
  const intl = useIntl()
  const [session] = useSession()
  return (
    <>
      <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-4 no-print">
        <NavLink href="https://help.eezyquote.com" title={intl.formatMessage({ defaultMessage: 'Support' })} type="link" />
        {session?.user && (
          <Feedback>
            <Button type="default" size="middle">
              {intl.formatMessage({ defaultMessage: 'Feedback' })}
            </Button>
          </Feedback>
        )}
        <ProfileDropdown />
      </div>
      <div className="-mr-2 flex items-center sm:hidden no-print print:hidden">
        {/* <!-- Mobile menu button --> */}
        <Button
          type="primary"
          ghost
          className="inline-flex items-center justify-center p-2 text-2xl border-none shadow-none hover:text-info no-print"
          aria-expanded="false"
          size="large"
          onClick={props.toggleMenu}
        >
          <span>
            <span className="sr-only">{intl.formatMessage({ defaultMessage: 'Open main menu' })}</span>
            <CgMenuRightAlt className="anticon" />
          </span>
        </Button>
      </div>
      <div className="hidden print:flex not-sr-only h-full justify-center items-center">
        <a href="https://www.eezyquote.com" className="not-sr-only">
          https://www.eezyquote.com
        </a>
      </div>
    </>
  )
}

const MobileContainer = (props: { isOpen: boolean; close: () => void; open: () => void; linkGroups: Array<LinkType[]> }) => {
  const intl = useIntl()
  const [session] = useSession()
  return (
    <MobileDrawer
      {...props}
      topSection={
        <>
          {session?.user && (
            <Feedback>
              <Button type="primary" ghost block size="large">
                {intl.formatMessage({ defaultMessage: 'Feedback' })}
              </Button>
            </Feedback>
          )}
          <Link href="/support">
            <Button type="default" block size="large">
              {intl.formatMessage({ defaultMessage: 'Support' })}
            </Button>
          </Link>
          <FancyRequestButton block size="large" />
        </>
      }
      bottomSection={
        session?.user && (
          <div>
            <div className="pt-4 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full">
                    <Avatar src={session.user?.image} size={40} alt={session.user?.email || session.user?.slug || ''} />
                  </div>
                </div>
                <div className="ml-3">
                  {session.user?.slug !== session.user.id && (
                    <div className="text-base font-medium text-gray-800">{session.user?.name || `@${session.user?.slug}`}</div>
                  )}

                  <div className="text-sm font-medium text-gray-500">{session.user?.email}</div>
                </div>
                <NotificationBell />
              </div>
            </div>
          </div>
        )
      }
    />
  )
}
