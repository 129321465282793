import { cn } from '@eq/utils/classnames'
import { Button, ButtonProps, Dropdown, Menu } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

const links = [
  { title: 'View All', description: 'A list of all public requests.', path: '/requests' },
  { title: 'Create New', description: 'Create a new request for a sector.', path: '/request/create' },
]

export default function FancyRequestButton(props: ButtonProps) {
  const { pathname } = useRouter()

  const menu = useMemo(() => {
    return (
      <Menu activeKey={pathname} className="bg-accent-1">
        {links.map((link) => {
          const href = link.path

          const active = pathname.indexOf(link.path) !== -1

          return (
            <Menu.Item key={href}>
              <Link href={href}>
                <a className="flex flex-col py-2 max-w-screen-xs whitespace-normal">
                  <span className={cn(active ? 'font-semibold text-blue-400' : 'font-normal')}>{link.title}</span>
                  <span className={cn(active ? 'text-blue-400' : 'text-gray-500')}>{link.description}</span>
                </a>
              </Link>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }, [pathname])

  return (
    <Dropdown overlay={menu} overlayClassName="z-[1300]">
      <Button type="ghost" className="border font-medium  hover:shadow-sm" {...props}>
        Requests
      </Button>
    </Dropdown>
  )
}
